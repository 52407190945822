import img1 from '../../images/service/1.webp'
import img2 from '../../images/service/2.webp'
import img3 from '../../images/service/3.webp'
import img4 from '../../images/service/4.webp'
import img5 from '../../images/service/5.webp'
import img6 from '../../images/service/6.webp'



export const data = [
	{
		id: 1,
		h2: 'Перетяжка руля кожей',
		name: 'гарантия стиля и качества',
		text: `Перетянем руль в натуральную кожу, экокожу, алькантару.`,
		img: img1,
		alt: '',
		link: 'перетяжка-руля'
	},
	{
		id: 2,
		h2: 'Перетяжка салона автомобиля',
		name: 'гарантия стиля и качества',
		text: `Перетянем сидения, подлокотник, дверные подлокотники, дверные карты в натуральную кожу, экокожу, алькантару.`,
		img: img2,
		alt: '',
		link: 'перетяжка-салона',
	},
	{
		id: 3,
		h2: 'Перетяжка потолка',
		name: 'гарантия стиля и качества',
		text: `Перетянем потолок в натуральную кожу, алькантару.`,
		img: img3,
		alt: '',
		link: 'перетяжка-потолка',
	},
	{
		id: 4,
		h2: 'Перетяжка ручек, чехлов МКПП/АКПП',
		name: 'гарантия стиля и качества',
		text: `Перетянем ручку и(или) чехол МКПП/АКПП в натуральную кожу, экокожу, алькантару.`,
		img: img4,
		alt: '',
		link: 'перетяжка-ручки-кпп',
	},
	{
		id: 5,
		h2: 'Перетяжка торпедо',
		name: 'гарантия стиля и качества',
		text: `Перетянем торпедо в натуральную кожу, экокожу, алькантару.`,
		img: img5,
		alt: '',
		link: 'перетяжка-торпедо',
	},
	{
		id: 6,
		h2: 'Ремонт салона автомобиля',
		name: 'гарантия стиля и качества',
		text: `Производим ремонт сидений, ручек, потолка и других элементов.`,
		img: img6,
		alt: '',
		link: 'ремонт-салона'
	},
	
]