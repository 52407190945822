import React from 'react'
import { motion } from "framer-motion"
import { titleAnimation2, titleAnimation } from '../../constants/animation/AnimationConst'
import logo3 from '../../images/logo/logo3.webp'
import { Image } from 'antd'

export const SectionGreyMain = () => {
	return (
		<section className='bg-[#0C142C] text-[#F4F4F4] px-5 py-10 mt-20 relative'>
			<motion.div
				className=''
				initial="hidden"
				whileInView="visible"
			>
				<motion.div
					className='mb-4'
					variants={titleAnimation}
				>
					<Image src={logo3} width='300px' />
				</motion.div>
				<motion.p
					className='font-light leading-6 text-sm tracking-wide'
					variants={titleAnimation2}
				>
					Автоателье "APROSTYLE" по ремонту и перетяжке салона автомобиля – это место, где профессиональные мастера занимаются восстановлением и улучшением внешнего вида салона автомобиля. В автоателье можно заказать перетяжку сидений, дверных обивок, потолка, руля, а также ремонт поврежденных элементов салона.
					Перетяжка сидений – один из наиболее популярных видов работ, которые выполняются в автоателье. При перетяжке сидений мастера могут использовать различные материалы: кожу, винил, ткань. Каждый материал имеет свои преимущества и недостатки, поэтому выбор материала зависит от личных предпочтений владельца автомобиля.
					Ремонт поврежденных элементов салона также является важной услугой, предоставляемой автоателье. Мастера способны восстановить различные элементы интерьера, такие как панели приборов, дверные обивки, потолок и т.д. При этом используются различные методы ремонта, включая заплатку, краску, шлифовку и т.д.
					
					Одним из главных преимуществ работы с автоателье "APROSTYLE" является индивидуальный подход к каждому клиенту. Мастера могут предложить различные варианты дизайна и материалов для перетяжки и ремонта салона автомобиля, учитывая личные предпочтения и бюджет клиента.
					
					В целом, автоателье по ремонту и перетяжке салона автомобиля является отличным местом для тех, кто хочет улучшить внешний вид и функциональность салона своего автомобиля. Профессиональные мастера смогут выполнить любые работы по перетяжке и ремонту салона, а индивидуальный подход к каждому клиенту позволит получить идеальный результат.

					Наш опыт более чем 20 лет, за это время мы получили огромный опыт и собрали команду настоящих профессионалов.
					Обращаясь к нам Вы получаете качественный сервис по доступным ценам.
				</motion.p>
			</motion.div>
		</section>
	)
}
