import img1 from '../../images/portfolio/rul/1.webp'
import img3 from '../../images/portfolio/rul/3.webp'

import img5 from '../../images/portfolio/rul/5.webp'
import img6 from '../../images/portfolio/rul/6.webp'

import img10 from '../../images/portfolio/rul/10.webp'
import img11 from '../../images/portfolio/rul/11.webp'
import img12 from '../../images/portfolio/rul/12.webp'

import img15 from '../../images/portfolio/rul/15.webp'
import img16 from '../../images/portfolio/rul/16.webp'
import img17 from '../../images/portfolio/rul/17.webp'
import img18 from '../../images/portfolio/rul/18.webp'

import img20 from '../../images/portfolio/rul/20.webp'
import imgSalon1 from '../../images/portfolio/salon/1.webp'
import imgSalon2 from '../../images/portfolio/salon/2.webp'
import imgSalon3 from '../../images/portfolio/salon/3.webp'
import imgSalon4 from '../../images/portfolio/salon/4.webp'
import video1 from '../../images/portfolio/rul/video/1.mp4'

export const dataRul = [
	{
		id: 0,
		title: 'Hyundai',
		video:video1
	},
	{
		id: 1,
		title: 'AUDI',
		img:img1
	},
	{
		id: 3,
		title: 'mercedes',
		img:img3
	},
	
	{
		id: 5,
		title: 'mercedes',
		img:img5
	},
	{
		id: 6,
		title: 'RANGE ROVER',
		img:img6
	},
	
	{
		id: 10,
		title: 'AUDI',
		img:img10
	},
	{
		id: 11,
		title: 'bmw',
		img:img11
	},
	{
		id: 12,
		title: 'porsche',
		img:img12
	},

	{
		id: 15,
		title: 'tesla',
		img:img15
	},
	{
		id: 16,
		title: 'mercedes',
		img:img16
	},
	{
		id: 17,
		title: 'toyota',
		img:img17
	},
	{
		id: 18,
		title: 'mercedes',
		img:img18
	},

	{
		id: 20,
		title: 'porsche',
		img:img20
	},
]
export const dataSalon = [
	{
		id: 1,
		title: 'mercedes',
		img:imgSalon1
	},
	{
		id: 2,
		title: 'mercedes',
		img:imgSalon2
	},
	{
		id: 3,
		title: '',
		img:imgSalon3
	},
	{
		id: 4,
		title: 'porsche',
		img:imgSalon4
	},
]